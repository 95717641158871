@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

@font-face {
  font-family: 'light';
  src: local('Inter-Regular'), url(../assets/fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'medium';
  src: local('Inter-Medium'), url(../assets/fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'semiBold';
  src: local('Inter-SemiBold'), url(../assets/fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'bold';
  src: local('Inter-Black'), url(../assets/fonts/Inter-Black.ttf) format('truetype');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'medium', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  background: #f8fafc !important;
}

#root {
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) div:focus {
  box-shadow: inherit;
}

:focus-visible {
  outline: #1d84ff50 auto 1px;
}

input,
select,
textarea {
  font-family: 'light', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif !important;
}

textarea {
  padding: 10px 16px !important;
  max-height: 300px;
}

.brand-link {
  color: #0d99ff;
}

.primary-link {
  color: #111111;
}

.brand-link:hover,
.primary-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 750px) {
  .desktop {
    display: none !important;
  }
}

@media screen and (min-width: 750px) {
  .mobile {
    display: none !important;
  }
}

.disabled-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.hidden {
  display: none !important;
}

.hover-underline:hover {
  text-decoration: underline;
}

.chakra-modal__content {
  box-shadow: none !important;
}

.disable-focus > * {
  box-shadow: none !important;
}

.table-row-link-hover:hover td {
  text-decoration: underline;
  cursor: pointer;
}

.table-row-no-border-bottom td {
  border-bottom: 0px;
}

.capitalize {
  text-transform: capitalize;
}

.capitalize-first-letter:first-letter {
  text-transform: capitalize;
}

/* MULTI SELECT STYLES -------------------------------------------- */
.chakra-react-select__menu-portal {
  z-index: 10000000 !important;
}

.chakra-react-select__placeholder {
  font-family: 'light';
  color: #718096 !important;
}

.chakra-react-select__control {
  border: 2px solid !important;
  border-color: transparent !important;
  border-radius: var(--chakra-radii-lg) !important;
  margin: -2px;
  width: calc(100% + 4px);
}

.chakra-react-select__control[data-focus] {
  border-color: #bdc4cf !important;
  box-shadow: inherit !important;
}

#input-element:focus {
  border-color: #bdc4cf !important;
  box-shadow: inherit !important;
}

.chakra-react-select__control--is-focused .chakra-react-select__value-container {
  background: transparent !important;
}

.chakra-react-select__value-container {
  background: var(--chakra-colors-gray-100) !important;
  border: none !important;
  height: 40px;
}

.chakra-react-select__indicator {
  border: 0px !important;
  background: transparent !important;
}

.chakra-react-select__indicators {
  width: 1.5rem;
  height: 100%;
  right: 0.5rem;
  margin-right: -2px;
  position: absolute;
  color: currentColor;
  font-size: 1.25rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  pointer-events: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.chakra-react-select__indicator-separator {
  display: none;
}

.chakra-react-select__multi-value {
  background: white !important;
  border: 1px solid #edf2f6 !important;
}

.chakra-react-select__value-container--is-multi.chakra-react-select__value-container--has-value {
  padding-left: 6px !important;
}

.chakra-react-select__clear-indicator {
  display: none !important;
}

.chakra-toast {
  font-size: 14px !important;
}

.chakra-toast .chakra-alert__icon {
  width: 16px !important;
}

.chakra-button__icon {
  margin-inline-end: 0.35rem !important;
}

.chakra-menu__menu-list {
  font-size: 14px;
}

/* MULTI SELECT STYLES -------------------------------------------- */

.draggable-cursor * {
  cursor: move !important; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-move !important; /* Chrome 1-21, Safari 4+ */
  cursor: -moz-move !important; /* Firefox 1.5-26 */
  cursor: move !important; /* W3C standards syntax, should come least */
}

.dragging-ghost {
  position: relative;
}

.dragging-ghost::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: #edf2f7;
  border-radius: 8px;
  z-index: 5;
}

/* SPEC IFRAME STYLES START -------------------------------------------- */

.disable-all-mouse-events * {
  pointer-events: none;
}

.spec-container-iframe {
  padding: 40px 0px !important;
}

@media screen and (max-width: 750px) {
  .spec-container-iframe {
    padding: 0px !important;
  }
}

.show-body-scrollbars {
  overflow-y: scroll;
  overflow-x: hidden;
}

.show-body-scrollbars::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.show-body-scrollbars::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* SPEC IFRAME STYLES END -------------------------------------------- */

.placed-element-pulse {
  box-shadow: 0 0 0 0 rgba(134, 143, 154, 1);
  animation: pulse 2s;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(134, 143, 154, 0.7);
  }

  70% {
    box-shadow: 0 0 0 6px rgba(134, 143, 154, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(134, 143, 154, 0);
  }
}

/* -------------------------------------------- */

.navigation-button-active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 1px;
  background: #ffffff;
}

.standalone-text-editor {
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

.gray-text-editor {
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  border: 2px solid;
  border-color: transparent;
  border-radius: 6px;
  background: #edf2f7;
}

.gray-text-editor:hover {
  background: var(--chakra-colors-gray-200);
}

.gray-text-editor:focus-within {
  background: transparent;
  border-color: #bdc4cf !important;
  box-shadow: inherit !important;
}

.popup-box {
  box-shadow: none !important;
}

em-emoji-picker {
  max-width: 100%;
}

@media screen and (max-width: 750px) {
  .constrain-mobile-width-mobile {
    max-width: calc(100vw - 44px) !important;
  }
}

/* INTRO.JS STYLES START -------------------------------------------- */

.introjs-helperLayer {
  border-radius: 8px !important;
  box-shadow: #111111 0px 0px 0px 2px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
  background: #f8fafc !important;
}

.transparent-background {
  background: transparent !important;
}

.introjs-tooltipReferenceLayer {
  font-family: 'medium' !important;
}

.introjs-tooltip {
  box-shadow: none !important;
  border-radius: 8px !important;
  min-width: 300px !important;
}

.introjs-tooltip-header {
  padding: 16px 20px !important;
  border-bottom: 1px solid #e2e8f0;
  display: none !important;
}

.introjs-tooltip-title {
  font-family: 'semiBold' !important;
  color: #1a202c !important;
  font-size: 14px !important;
  line-height: inherit !important;
  font-weight: normal !important;
}

.introjs-skipbutton {
  padding: 0px !important;
  height: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #868f9a !important;
}

.introjs-tooltiptext {
  font-family: 'light' !important;
  color: #3c4257 !important;
  padding: 16px 20px !important;
  font-size: 14px !important;
  white-space: pre-wrap;
  overflow: hidden;
  overflow-wrap: break-word;
}

.introjs-bullets {
  padding: 0 16px 16px 16px !important;
}

.introjs-bullets ul li a {
  background: #bdc4cf !important;
}

.introjs-bullets ul li a.active {
  width: 6px !important;
  background: #1a202c !important;
}

.introjs-tooltipbuttons {
  padding: 16px 20px !important;
  border-top: 1px solid #e2e8f0 !important;
}

.introjs-button {
  color: #1a202c !important;
  border-radius: 6px !important;
  font-family: 'medium' !important;
  text-shadow: none !important;
  background: white !important;
  border: 1px solid #e2e8f0 !important;
}

.introjs-nextbutton {
  background: #111111 !important;
  color: white !important;
  border: none !important;
}

.introjs-progress {
  background: #edf2f7 !important;
  border-radius: 50px !important;
  margin: 0 20px 16px 20px !important;
}

.introjs-progressbar {
  background: #111111 !important;
}

.introjs-fixParent {
  position: absolute !important;
}

/* INTRO.JS STYLES END -------------------------------------------- */
